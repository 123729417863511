import { useLabels } from "@/core/context/LabelsContext"
import { useProductsAdminLearnMode } from "@/core/context/ProductsAdminLearnModeContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { LearnModeSwitchFragment$key } from "@/experience/components/__generated__/LearnModeSwitchFragment.graphql"
import { LearnModeSwitch_EnrollUserForProductLearnModeMutation } from "@/experience/components/__generated__/LearnModeSwitch_EnrollUserForProductLearnModeMutation.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoIcon,
  DiscoModal,
  DiscoSpinner,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import { ButtonBase, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  productKey: LearnModeSwitchFragment$key
}

function LearnModeSwitch({ testid = "LearnModeSwitch", productKey }: Props) {
  const {
    isViewerInLearnModeForProduct,
    turnOnLearnModeForProduct,
    turnOffLearnModeForProduct,
    addProductMembershipToState,
    isLoading,
  } = useProductsAdminLearnMode()

  const product = useFragment<LearnModeSwitchFragment$key>(
    graphql`
      fragment LearnModeSwitchFragment on Product {
        id
        name
        status
        viewerMembership {
          id
        }
      }
    `,
    productKey
  )

  const enrollForm = useFormStore<LearnModeSwitch_EnrollUserForProductLearnModeMutation>(
    graphql`
      mutation LearnModeSwitch_EnrollUserForProductLearnModeMutation(
        $input: EnrollUserForProductLearnModeInput!
      ) {
        response: enrollUserForProductLearnMode(input: $input) {
          node {
            id
            ...ProductsAdminLearnModeContext_ProductMembershipFragment @relay(mask: false)
            product {
              ...LearnModeSwitchFragment
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      productId: product.id,
    }
  )

  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false)
  const isLearnModeOn = isViewerInLearnModeForProduct(product.id)
  const disabled = product.status === "draft"
  const classes = useStyles({ isLearnModeOn, disabled: product.status === "draft" })
  const labels = useLabels()
  const theme = useTheme()

  if (isLoading) return <DiscoSpinner size={"xs"} />

  return (
    <>
      <DiscoTooltip data-testid={"LearnModeSwitch.tooltip"} content={getTooltipContent()}>
        <span>
          <ButtonBase
            data-testid={`${testid}.learn-mode-switch`}
            className={classes.container}
            disabled={disabled}
            onClick={() => {
              if (isLearnModeOn) {
                // Turn off. If no product membership, do nothing.
                if (!product.viewerMembership?.id) return
                turnOffLearnModeForProduct(product.viewerMembership.id)
              } else {
                // Turn on. If not product membership, open modal to register first.
                if (!product.viewerMembership?.id) return setIsRegisterModalOpen(true)
                turnOnLearnModeForProduct(product.viewerMembership.id)
              }
            }}
          >
            <div className={classes.onOffTextContainer}>
              <DiscoText
                variant={"body-sm-600"}
                color={isLearnModeOn ? "common.white" : "groovy.neutral.600"}
              >
                {isLearnModeOn ? "ON" : "OFF"}
              </DiscoText>
            </div>
            <div className={classes.learningModeTextContainer}>
              <DiscoText variant={"body-sm-600"}>{"Learn Mode"}</DiscoText>
            </div>
          </ButtonBase>
        </span>
      </DiscoTooltip>

      <DiscoModal
        isOpen={isRegisterModalOpen}
        modalContentLabel={"Switch to Learn Mode"}
        testid={"learning-mode-switch"}
        hideHeader
        body={
          <div className={classes.enrollModalContentContainer}>
            {/* Text */}
            <DiscoIcon icon={"iconsax.teacher"} color={theme.palette.text.primary} />
            <DiscoText variant={"body-lg-700"}>{"Switch to Learn Mode"}</DiscoText>
            <DiscoText
              align={"center"}
            >{`You need to enroll yourself into the ${labels.admin_experience.singular} "${product.name}" before you can enter Learn Mode.`}</DiscoText>

            {/* Buttons */}
            <div className={classes.enrollModalButtonsContainer}>
              <DiscoButton
                onClick={() => setIsRegisterModalOpen(false)}
                color={"grey"}
                variant={"outlined"}
                width={"100%"}
              >
                {"Cancel"}
              </DiscoButton>
              <DiscoButton
                testid={"LearnModeSwitch.enroll-modal.enroll-button"}
                onClick={enrollUser}
                width={"100%"}
                shouldDisplaySpinner={enrollForm.isSubmitting}
                disabled={enrollForm.isSubmitting}
              >
                {"Enroll Now"}
              </DiscoButton>
            </div>
          </div>
        }
      />
    </>
  )

  async function enrollUser() {
    const { didSave, response } = await enrollForm.submit(enrollForm.state)
    if (!didSave || !response?.node) return

    addProductMembershipToState(response.node.id, {
      productId: product.id,
      isLearnMode: true,
      chatChannelIds: response.node.product.chatChannels.edges.map(
        (edge) => edge.node.id
      ),
    })
  }

  function getTooltipContent() {
    if (product.status === "draft") {
      return "Learn Mode is not available when in draft mode."
    }
    return "When Learn Mode is ON, you can complete the curriculum, and your progress will be recorded in reports."
  }
}

type StyleProps = {
  isLearnModeOn: boolean
  disabled: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ isLearnModeOn, disabled }: StyleProps) => ({
    height: "40px",
    width: "100%",
    padding: theme.spacing(0.5),
    borderRadius: "100px",
    border: `1px solid ${
      isLearnModeOn ? theme.palette.primary.main : theme.palette.groovy.neutral[300]
    }`,
    backgroundColor: isLearnModeOn
      ? theme.palette.primary.main
      : theme.palette.groovy.neutral[200],
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: isLearnModeOn ? "row" : "row-reverse",
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? "not-allowed" : "pointer",
  }),
  onOffTextContainer: {
    padding: theme.spacing(0, 1),
    flexGrow: 1,
  },
  learningModeTextContainer: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "20px",
    padding: theme.spacing(0, 1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  enrollModalContentContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  enrollModalButtonsContainer: {
    display: "flex",
    gap: theme.spacing(1),
    width: "100%",
  },
}))

export default LearnModeSwitch
