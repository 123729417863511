import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { handleLocalStorageError } from "@/onboarding/utils/hooks/useLocalStorageState"
import { Spacing } from "@assets/style/appMuiTheme"
import makeSpacingStyles from "@assets/style/util/makeSpacingStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import DiscoLink from "@disco-ui/link/DiscoLink"
import DiscoText from "@disco-ui/text/DiscoText"
import { Alert, AlertProps } from "@material-ui/lab"
import { ReactNode, useEffect, useState } from "react"

export interface DiscoAlertProps
  extends Omit<AlertProps, "variant" | "severity" | "title">,
    Spacing {
  title?: ReactNode
  learnMoreLink?: string
  learnMoreText?: string
  message: ReactNode
  /**
   *  A unique key to make the alert dismissiable.
   *  The alert will be dismissed if the key is found in localStorage
   */
  dismissibleKey?: string
  gradientBackground?: string
  media?: ReactNode
  severity?: AlertProps["severity"] | "default"
  cta?: ReactNode
}

function DiscoAlert(props: DiscoAlertProps) {
  const {
    title,
    message,
    learnMoreLink,
    learnMoreText,
    severity = "info",
    classes: customClasses,
    dismissibleKey,
    onClose,
    media,
    cta,
    gradientBackground,
    margin = 0,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    padding = 0,
    paddingTop = 2,
    paddingBottom = 2,
    paddingLeft = 2,
    paddingRight = 2,
    ...rest
  } = props

  const classes = useStyles({
    gradientBackground,
    severity,
  })
  // Pack spacing props into a single object
  const spacing = {
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  }
  const spacingStyles = useSpacingStyles(spacing)
  const [dismissAlert, setDismissAlert] = useState(false)
  const activeOrganization = useActiveOrganization()
  // Scope each dismissible key to the viewer membership id
  // so the behaviour is unique to each member
  const scopedDismissibleKey = dismissibleKey
    ? `${activeOrganization?.viewerMembership?.id || "DiscoAlert"}-${dismissibleKey}`
    : null

  // Check if the alert should be dismissed if dismissibleKey is provided
  useEffect(() => {
    if (!scopedDismissibleKey) return
    try {
      const dismissiable = localStorage.getItem(scopedDismissibleKey)
      if (!dismissiable) return
      // If the key is found in localStorage, dismiss the alert
      setDismissAlert(true)
    } catch (err) {
      // ignore
    }
  }, [setDismissAlert, scopedDismissibleKey])

  if (dismissAlert) return null

  return (
    <Alert
      classes={mergeClasses(
        {
          root: classes.root,
          message: classes.message,
          icon: classes.icon,
          action: classes.action,
          standardInfo: classes.standardInfo,
          standardSuccess: classes.standardSuccess,
          standardWarning: classes.standardWarning,
          standardError: classes.standardError,
        },
        customClasses,
        spacingStyles
      )}
      severity={severity === "default" ? "info" : severity}
      {...rest}
      // By passing a handler here, a close icon button is displayed
      onClose={onClose ?? (dismissibleKey ? handleDismissAlert : undefined)}
      // If media is provided, don't show the icon
      icon={media ? false : props.icon}
    >
      {media && <div className={classes.mediaWrapper}>{media}</div>}
      <div>
        {title ? (
          typeof title === "string" ? (
            <DiscoText variant={"body-md-600"}>{title}</DiscoText>
          ) : (
            title
          )
        ) : null}
        {typeof message === "string" ? (
          <DiscoText variant={"body-sm"}>{message}</DiscoText>
        ) : (
          message
        )}
        {learnMoreLink && (
          <DiscoLink
            data-testid={"DiscoAlert.learn-more-link"}
            target={"_blank"}
            href={learnMoreLink}
            color={"currentColor"}
            textVariant={"body-sm-600"}
            textDecoration={"underline"}
          >
            {learnMoreText || "Learn More"}
          </DiscoLink>
        )}
      </div>
      {cta && <div>{cta}</div>}
    </Alert>
  )

  function handleDismissAlert() {
    if (!scopedDismissibleKey) return
    try {
      // Save the dismissibleKey to localStorage
      localStorage.setItem(scopedDismissibleKey, Date.now().toString())
      setDismissAlert(true)
    } catch (err) {
      handleLocalStorageError(err)
    }
  }
}

type StyleProps = {
  gradientBackground?: string
} & Pick<DiscoAlertProps, "severity">

const useStyles = makeUseStyles((theme) => ({
  message: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(4),
    margin: 0,
    padding: 0,
    width: "100%",
    wordBreak: "break-word",
    "& p, span": {
      color: "currentColor",
    },
  },
  root: {
    borderRadius: theme.measure.borderRadius.big,
  },
  mediaWrapper: {
    display: "grid",
    placeItems: "center",
  },
  action: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(0.5),
  },
  standardInfo: ({ gradientBackground, severity }: StyleProps) => ({
    background:
      gradientBackground ??
      theme.palette.groovy[severity === "default" ? "neutral" : "blue"][100],
    color: gradientBackground
      ? theme.palette.common.white
      : theme.palette.groovy[severity === "default" ? "grey" : "blue"][700],
  }),
  standardError: {
    backgroundColor: theme.palette.groovy.red[100],
    color: theme.palette.groovy.red[700],
  },
  standardSuccess: {
    backgroundColor: theme.palette.groovy.green[100],
    color: theme.palette.groovy.green[700],
  },
  standardWarning: {
    backgroundColor: theme.palette.groovy.yellow[100],
    color: theme.palette.groovy.yellow[700],
  },
  icon: {
    /** Override the default icon colour css specificity */
    color: "currentColor !important",
    padding: theme.spacing(0),
    marginTop: "1px",
    width: "20px",
    height: "20px",
    "& svg": {
      color: "currentColor",
      width: "20px",
      height: "20px",
    },
  },
}))

const useSpacingStyles = makeSpacingStyles()

export default DiscoAlert
