import makeUseStyles from "@assets/style/util/makeUseStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import styleIf from "@assets/style/util/styleIf"
import DiscoIcon, { DiscoIconKinds } from "@disco-ui/icon/DiscoIcon"
import { IconsaxIconKinds } from "@disco-ui/icon/iconMaps"
import DiscoLink from "@disco-ui/link/DiscoLink"
import DiscoSpinner from "@disco-ui/spinner/DiscoSpinner"
import {
  ButtonBase,
  ButtonBaseClassKey,
  ButtonBaseProps,
  lighten,
  Theme,
  useTheme,
} from "@material-ui/core"
import { LinkProps } from "@material-ui/core/Link"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { Skeleton } from "@material-ui/lab"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import classNames from "classnames"
import { LocationDescriptorObject } from "history"
import React, { useMemo } from "react"

export interface DiscoButtonProps
  extends Pick<LinkProps, "underline">,
    Omit<ButtonBaseProps, "color" | "width" | "classes" | "height">,
    Partial<Pick<StyleProps, "width" | "color">> {
  testid?: string
  shouldDisplaySpinner?: boolean
  shouldDisplaySpinnerWithText?: boolean
  component?: React.ElementType
  href?: string
  to?: string | LocationDescriptorObject
  target?: string
  rel?: string
  leftIcon?: React.ReactElement | DiscoIconKinds | IconsaxIconKinds
  rightIcon?: React.ReactElement | DiscoIconKinds | IconsaxIconKinds
  size?: DiscoButtonSize
  color?: DiscoButtonColor
  variant?: DiscoButtonVariant
  classes?: Partial<ClassNameMap<ButtonBaseClassKey | "leftIcon" | "rightIcon">>
  noPadding?: boolean
  borderRadius?: number
  customButtonColor?: ButtonColorStyle
  onlyIconInMobile?: boolean
  stopPropagation?: boolean
}

const DiscoButton = React.forwardRef<HTMLButtonElement, DiscoButtonProps>(
  function DiscoButton(
    {
      children,
      shouldDisplaySpinner,
      shouldDisplaySpinnerWithText,
      testid = "DiscoButton",
      leftIcon,
      rightIcon,
      classes: customClasses,
      size = "medium",
      color = "primary",
      variant = "contained",
      width = "",
      type,
      borderRadius,
      noPadding,
      customButtonColor,
      onlyIconInMobile = false,
      stopPropagation,
      onClick,
      ...props
    },
    ref
  ) {
    const DiscoButtonColor = useDiscoButtonColorStyle(color)
    const colorStyle = customButtonColor || DiscoButtonColor
    const noHover = !onClick && !props.to && type !== "submit" && !props.href
    const hasText = typeof children === "string"
    const isMobile = useIsMobile()

    const classes = useDiscoButtonStyles({
      colorStyle,
      width,
      color,
      variant,
      noHover,
      hasText,
      noPadding,
      size,
      leftIconOnly: isMobile && onlyIconInMobile,
      borderRadius,
    })
    return (
      <ButtonBase
        ref={ref}
        component={props.disabled || !props.to ? undefined : DiscoLink}
        classes={mergeClasses(
          { root: classes.root },
          {
            root: customClasses?.root,
            disabled: customClasses?.disabled,
            focusVisible: customClasses?.focusVisible,
          }
        )}
        data-testid={testid}
        type={type}
        onClick={
          onClick
            ? (e) => {
                if (stopPropagation) {
                  e.stopPropagation()
                }
                onClick(e)
              }
            : undefined
        }
        {...props}
      >
        {!shouldDisplaySpinner && !shouldDisplaySpinnerWithText && leftIcon && (
          <div
            className={classNames(
              classes.iconBase,
              classes.leftIcon,
              customClasses?.leftIcon
            )}
          >
            {typeof leftIcon === "string" ? <DiscoIcon icon={leftIcon} /> : leftIcon}
          </div>
        )}
        {(!onlyIconInMobile || !isMobile) && (
          <>
            {shouldDisplaySpinnerWithText ? (
              <>
                <DiscoSpinner
                  color={colorStyle.color}
                  size={"sm"}
                  className={classes.spinnerWithText}
                />

                {typeof children === "string" ? (
                  <span data-testid={`${testid}.text`}>{children}</span>
                ) : (
                  children
                )}
              </>
            ) : shouldDisplaySpinner ? (
              <DiscoSpinner color={colorStyle.color} size={"sm"} />
            ) : typeof children === "string" ? (
              <span data-testid={`${testid}.text`}>{children}</span>
            ) : (
              children
            )}
            {!shouldDisplaySpinner && !shouldDisplaySpinnerWithText && rightIcon && (
              <div
                className={classNames(
                  classes.iconBase,
                  classes.rightIcon,
                  customClasses?.rightIcon
                )}
              >
                {typeof rightIcon === "string" ? (
                  <DiscoIcon icon={rightIcon} />
                ) : (
                  rightIcon
                )}
              </div>
            )}
          </>
        )}
      </ButtonBase>
    )
  }
)

export type DiscoButtonVariant = "contained" | "outlined" | "text" | "dashed"

export type DiscoButtonColor =
  | "primary"
  | "warning"
  | "success"
  | "error"
  | "cyan"
  | "yellow"
  | "grey"
  | "transparent"
  | "white"

export type DiscoButtonSize = "xsmall" | "small" | "medium" | "large"

export interface ButtonColorStyle {
  color: string
  backgroundColor: string
  border?: string
  hover: {
    backgroundColor: string
    color?: string
    border?: string
  }
}

const NEW_COLOR_CONFIGS: Record<DiscoButtonColor, (theme: Theme) => ButtonColorStyle> = {
  primary: (theme) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    hover: {
      backgroundColor: lighten(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.contrastText,
    },
  }),
  warning: (theme) => ({
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light,
    hover: {
      backgroundColor: lighten(theme.palette.warning.light, 0.1),
      color: theme.palette.warning.contrastText,
    },
  }),
  success: (theme) => ({
    color: theme.palette.groovy.green[600],
    backgroundColor: theme.palette.success.light,
    hover: {
      backgroundColor: lighten(theme.palette.success.main, 0.1),
      color: theme.palette.success.contrastText,
    },
  }),
  error: (theme) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.groovy.red[400],
    border: theme.palette.error.main,
    hover: {
      backgroundColor: theme.palette.groovy.red[500],
      color: theme.palette.error.contrastText,
      border: theme.palette.error.main,
    },
  }),
  cyan: (theme) => ({
    color: theme.palette.getContrastText(theme.palette.groovy.cyan[200]),
    backgroundColor: theme.palette.groovy.cyan[200],
    hover: {
      backgroundColor: theme.palette.groovy.cyan[300],
      color: theme.palette.getContrastText(theme.palette.groovy.cyan[300]),
    },
  }),
  yellow: (theme) => ({
    color: theme.palette.getContrastText(theme.palette.groovy.yellow[200]),
    backgroundColor: theme.palette.groovy.yellow[200],
    hover: {
      backgroundColor: theme.palette.groovy.yellow[300],
      color: theme.palette.getContrastText(theme.palette.groovy.yellow[300]),
    },
  }),
  grey: (theme) => ({
    color: theme.palette.getContrastText(theme.palette.background.paper),
    backgroundColor: theme.palette.background.paper,
    border: theme.palette.groovy.grey[200],
    hover: {
      backgroundColor: theme.palette.background.paper,
      border: theme.palette.groovy.neutral[300],
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
  }),
  transparent: (theme) => ({
    color: theme.palette.getContrastText(theme.palette.background.paper),
    backgroundColor: "transparent",
    hover: {
      backgroundColor: "transparent",
    },
  }),
  white: (theme) => ({
    color: theme.palette.getContrastText(theme.palette.common.white),
    backgroundColor: theme.palette.common.white,
    hover: {
      backgroundColor: theme.palette.groovy.neutral[100],
      color: theme.palette.getContrastText(theme.palette.groovy.neutral[100]),
    },
  }),
}

export function useDiscoButtonColorStyle(color: DiscoButtonColor): ButtonColorStyle {
  const theme = useTheme()
  return useMemo(() => {
    return NEW_COLOR_CONFIGS[color](theme)
  }, [color, theme])
}

type StyleProps = {
  color: DiscoButtonColor
  variant: DiscoButtonVariant
  colorStyle: ButtonColorStyle
  size: DiscoButtonSize
  width: string
  noHover: boolean
  hasText: boolean
  noPadding?: boolean
  leftIconOnly: boolean
  borderRadius?: number
}

export const useDiscoButtonStyles = makeUseStyles((theme) => ({
  root: (props: StyleProps) => {
    return {
      "& > span": {
        lineHeight: 1,
        whiteSpace: "nowrap",
        color: props.colorStyle.color,
        ...theme.typography["body-md"],
        ...theme.typography.modifiers.fontWeight[600],
      },
      "&:hover": {
        textDecoration: "none",
      },

      /** Default  */
      height: 40,
      maxHeight: "100%",
      width: props.width,
      padding: theme.spacing(1, 1.5, 1, 1.5),
      ...styleIf(props.noPadding, {
        padding: 0,
      }),

      transition: "background-color 0.4s ease",

      /** Color */
      color: props.colorStyle.color,
      backgroundColor: props.colorStyle.backgroundColor,

      "&.Mui-focusVisible": {
        backgroundColor:
          props.colorStyle.backgroundColor === "transparent"
            ? undefined
            : lighten(props.colorStyle.backgroundColor, 0.1),
      },

      /** Border */
      border: "none",
      ...styleIf(props.variant === "outlined", {
        border: `1.5px solid ${props.colorStyle.border}`,
        backgroundColor: theme.palette.background.paper,
      }),
      ...styleIf(props.variant === "dashed", {
        border: `1.5px dashed ${props.colorStyle.border}`,
        backgroundColor: theme.palette.background.paper,
      }),
      ...styleIf(props.variant === "text", {
        backgroundColor: theme.palette.background.paper,
      }),
      borderRadius: theme.measure.borderRadius.medium,
      ...styleIf(props.borderRadius, {
        borderRadius: props.borderRadius,
      }),

      "&:hover:not(:disabled, .Mui-disabled)": {
        ...styleIf(!props.noHover, {
          backgroundColor: props.colorStyle.hover.backgroundColor,
          ...styleIf(props.colorStyle.hover.color, {
            color: props.colorStyle.hover.color,
            "& span": {
              color: props.colorStyle.hover.color,
            },
            "& svg": {
              color: props.colorStyle.hover.color,
            },
          }),
          ...styleIf(props.variant === "outlined", {
            border: `1.5px solid ${props.colorStyle.hover.border}`,
            backgroundColor: theme.palette.background.paper,
          }),
          ...styleIf(props.variant === "dashed", {
            border: `1.5px dashed ${props.colorStyle.border}`,
            backgroundColor: theme.palette.background.paper,
          }),
          ...styleIf(props.variant === "text", {
            backgroundColor: props.colorStyle.border,
          }),
        }),
        ...styleIf(props.noHover, {
          cursor: "default",
        }),
      },
      "&.Mui-disabled": {
        cursor: "not-allowed",
        pointerEvents: "initial",
        userSelect: "none",
        opacity: 0.4,
      },
      /** size = "large" */
      ...styleIf(props.size === "large", {
        height: 48,
        borderRadius: theme.measure.borderRadius.big,
        padding: theme.spacing(1.5, 2, 1.5, 2),
      }),

      /** size = "small" */
      ...styleIf(props.size === "small", {
        height: 32,
        padding: theme.spacing(0.5, 1, 0.5, 1),
      }),

      ...styleIf(props.size === "xsmall", {
        height: 28,
        padding: theme.spacing(0.25, 0.5, 0.25, 0.5),
      }),
    }
  },
  iconBase: (props: StyleProps) => ({
    marginBottom: props.size === "xsmall" ? "4px" : "0px",
    height: props.size === "xsmall" ? "16px" : props.size === "small" ? "20px" : "24px",
    width: props.size === "xsmall" ? "16px" : props.size === "small" ? "20px" : "24px",
    "& > svg": {
      height: props.size === "xsmall" ? "16px" : props.size === "small" ? "20px" : "24px",
      width: props.size === "xsmall" ? "16px" : props.size === "small" ? "20px" : "24px",
      color: props.colorStyle.color,
      path: props.colorStyle.color,
    },
  }),
  leftIcon: (props: StyleProps) => ({
    ...styleIf(props.hasText && !props.leftIconOnly, { marginRight: theme.spacing(1) }),
  }),
  spinnerWithText: (props: StyleProps) => ({
    ...styleIf(props.hasText && !props.leftIconOnly, { marginRight: theme.spacing(1) }),
  }),
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}))

export function DiscoButtonSkeleton(props: {
  width?: string
  height?: string
  className?: string
}) {
  const { width, height, className } = props

  const theme = useTheme()

  return (
    <Skeleton
      variant={"rect"}
      className={className}
      style={{
        height: height || "40px",
        width,
        borderRadius: theme.measure.borderRadius.big,
      }}
    />
  )
}

export default DiscoButton
