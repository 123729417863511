import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { Spacing } from "@assets/style/appMuiTheme"
import makeSpacingStyles from "@assets/style/util/makeSpacingStyles"
import styleIf from "@assets/style/util/styleIf"
import {
  DiscoIcon,
  DiscoIconButton,
  DiscoIconKinds,
  DiscoText,
  DiscoTextSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import { darken, Theme, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React, { ReactNode, useEffect, useRef, useState } from "react"

export type DiscoTagProps = Spacing &
  TestIDProps & {
    name: string
    backgroundColor?: string | ((theme: Theme) => string)
    tooltipBackgroundColor?: string
    leftIcon?: ReactNode | DiscoIconKinds
    rightButton?: ReactNode
    rightButtonTooltip?: ReactNode
    middleTooltip?: ReactNode
    onDelete?: () => void
    color?: string
    tooltipContent?: string | ReactNode
    className?: string
    maxWidth?: number
    classes?: {
      tooltip?: string
      arrow?: string
    }
    dividerVariant?: DividerVariant
    dividerColor?: string
    deleteIconSize?: number
    borderRadius?: number
  }

type DividerVariant = "default" | "full-height"

const useSpacingStyles = makeSpacingStyles()

function DiscoTag(props: DiscoTagProps) {
  const {
    name,
    tooltipContent,
    leftIcon,
    rightButton,
    rightButtonTooltip,
    middleTooltip,
    color,
    onDelete,
    testid,
    dividerVariant = "default",
    dividerColor,

    // Spacing Props
    margin = 0,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    padding = 0,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    maxWidth,
    backgroundColor: propsBackgroundColor,
    tooltipBackgroundColor,
    className: customClassName,
    classes: customClasses,
    deleteIconSize,
    borderRadius,
    ...rest
  } = props

  const spacing = {
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  }
  const spacingStyles = useSpacingStyles(spacing)
  const theme = useTheme()

  // Determine contrasting font colour
  const backgroundColor =
    typeof propsBackgroundColor === "function"
      ? propsBackgroundColor(theme)
      : propsBackgroundColor || theme.palette.groovy.grey[200]
  const contrastColor = theme.palette.getContrastText(backgroundColor)
  const textColor = color || contrastColor
  const classes = useStyles({
    backgroundColor,
    textColor,
    rightButton: Boolean(rightButton),
    maxWidth,
    dividerVariant,
    dividerColor,
    deleteIconSize,
    borderRadius,
  })

  const textRef = useRef<HTMLSpanElement>(null)

  // Check if the text is truncated in the tag
  const [isTruncated, setIsTruncated] = useState(false)
  useEffect(() => {
    if (!textRef.current) return
    setIsTruncated(textRef.current.offsetWidth < textRef.current.scrollWidth)
  }, [])

  // Show the tooltip if the text is truncated or if there is tooltip content
  const showTooltip = isTruncated || Boolean(tooltipContent)

  return (
    // If tooltip is active, show the tooltip content, otherwise show the name
    <DiscoTooltip
      disabled={!showTooltip}
      content={tooltipContent || name}
      backgroundColor={tooltipBackgroundColor}
      classes={{ tooltip: customClasses?.tooltip, arrow: customClasses?.arrow }}
    >
      <div
        {...rest}
        data-testid={testid}
        className={classNames(classes.tagContainer, spacingStyles.root, customClassName)}
      >
        {leftIcon && (
          <span className={classes.leftIconContainer}>
            {typeof leftIcon === "string" ? (
              <DiscoIcon
                icon={leftIcon as DiscoIconKinds}
                width={16}
                height={16}
                color={contrastColor}
              />
            ) : (
              leftIcon
            )}
          </span>
        )}
        <DiscoTooltip content={middleTooltip}>
          <DiscoText
            ref={textRef}
            setColor={textColor}
            className={classes.text}
            variant={"body-xs-600"}
            truncateText={1}
          >
            {name}
          </DiscoText>
        </DiscoTooltip>
        {rightButton && (
          <DiscoTooltip content={rightButtonTooltip}>
            <span className={classes.rightButtonContainer}>{rightButton}</span>
          </DiscoTooltip>
        )}
        {onDelete && (
          <DiscoIconButton
            testid={`${testid}.delete-button`}
            onClick={onDelete}
            className={classes.deleteButton}
          >
            <CloseIcon />
          </DiscoIconButton>
        )}
      </div>
    </DiscoTooltip>
  )
}

type StyleProps = {
  backgroundColor: string
  textColor: string
  rightButton?: boolean
  maxWidth?: number
  dividerVariant?: DividerVariant
  dividerColor?: string
  borderRadius?: number
  deleteIconSize?: number
}

const useStyles = makeUseStyles((theme) => ({
  tagContainer: ({
    backgroundColor,
    rightButton,
    maxWidth,
    borderRadius,
  }: StyleProps) => ({
    backgroundColor,
    padding: rightButton ? theme.spacing(0.5, 0, 0.5, 1) : theme.spacing(0.5, 1),
    borderRadius: borderRadius ?? theme.measure.borderRadius.default,
    width: "fit-content",
    display: "inline-flex",
    justifyContent: "center",
    height: "28px",
    alignItems: "center",
    ...styleIf(maxWidth, { maxWidth: `${maxWidth}px` }),
    ...styleIf(backgroundColor === theme.palette.background.paper, {
      border: theme.palette.constants.borderSmall,
    }),
  }),
  text: ({ rightButton, dividerVariant, dividerColor }: StyleProps) => ({
    width: "max-content",
    ...styleIf(rightButton, {
      paddingRight: theme.spacing(1),
      ...styleIf(dividerVariant === "default", {
        borderRight: theme.palette.constants.borderSmall,
        borderRightColor: `${
          dividerColor ?? theme.palette.groovy.neutral[600]
        } !important`,
      }),
    }),
  }),
  leftIconContainer: {
    marginRight: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
  },
  rightButtonContainer: ({
    backgroundColor,
    dividerVariant,
    dividerColor,
  }: StyleProps) => ({
    lineHeight: "1em",
    padding: theme.spacing(0.75, 1),
    borderRadius: "0 4px 4px 0",
    ...styleIf(dividerVariant === "full-height", {
      borderLeft: theme.palette.constants.borderSmall,
      borderLeftColor: dividerColor ?? theme.palette.groovy.neutral[600],
    }),
    "&:hover:not(:disabled):not(.Mui-disabled)": {
      backgroundColor: darken(backgroundColor, 0.04),
      cursor: "pointer",
    },
  }),
  deleteButton: ({ textColor, backgroundColor, deleteIconSize }: StyleProps) => ({
    marginLeft: theme.spacing(0.5),
    padding: 0,
    "& svg": {
      height: deleteIconSize ?? 16,
      width: deleteIconSize ?? 16,
      "& path": {
        color: textColor,
      },
    },
    "&:hover": {
      backgroundColor,
    },
  }),
}))

export const DiscoTagSkeleton: React.FC = () => {
  const theme = useTheme()
  const backgroundColor = theme.palette.groovy.grey[200]
  const textColor = theme.palette.getContrastText(backgroundColor)
  const classes = useStyles({ backgroundColor, textColor })

  return (
    <div className={classes.tagContainer}>
      <DiscoTextSkeleton width={"75px"} />
    </div>
  )
}

export default DiscoTag
