import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoIconButton from "@disco-ui/button/DiscoIconButton"
import DiscoIcon from "@disco-ui/icon/DiscoIcon"
import DiscoInput, { DiscoInputProps } from "@disco-ui/input/DiscoInput"
import DiscoText from "@disco-ui/text/DiscoText"
import { useFormControl } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useEffect, useRef, useState } from "react"

type Props = Omit<DiscoInputProps, "onChange"> &
  TestIDProps & {
    size?: "large" | "medium"
    truncateText?: number
    // Always show the input instead of static text if true
    showInput?: boolean
    onStartEdit?: () => void
    onStopEdit?: () => void
    onChange?: (value: string) => void
  }

export default function DiscoClickToEditInput(props: Props) {
  const {
    testid = "DiscoClickToEditInput",
    size = "medium",
    truncateText = 1,
    showInput,
    onStartEdit,
    onStopEdit,
    onChange,
    ...inputProps
  } = props
  const classes = useStyles({ size })
  const [isInput, setIsInput] = useState(inputProps.autoFocus || showInput || false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const formControl = useFormControl()
  const valueStr =
    inputProps.value && typeof inputProps.value === "string" ? inputProps.value : ""
  const initialValue = useRef(valueStr)

  useEffect(() => {
    if (isInput && inputRef.current) {
      // Focus input after it is rendered, fix for Chrome
      setTimeout(() => inputRef.current?.focus(), 50)
    }
  }, [isInput])

  const iconSize = size === "large" ? 24 : 18

  return isInput || showInput ? (
    <DiscoInput
      data-testid={`${testid}.editable`}
      {...inputProps}
      classes={{
        root: classNames(classes.inputRoot, inputProps.classes?.root),
        input: classNames(classes.input, inputProps.classes?.input),
      }}
      inputRef={inputRef}
      onChange={(e) => onChange?.(e.target.value)}
      onFocus={handleStartEdit}
      onBlur={handleStopEdit}
      onKeyDown={handleKeyDown}
    />
  ) : (
    <div className={classNames(classes.static, { [classes.error]: formControl?.error })}>
      <DiscoText
        color={inputProps.value ? "text.primary" : "text.disabled"}
        testid={testid}
        variant={size === "large" ? "heading-md" : "body-md-600"}
        truncateText={truncateText}
      >
        {valueStr || inputProps.placeholder || "Click to edit"}
      </DiscoText>
      <DiscoIconButton
        testid={`${testid}.edit-button`}
        size={"small"}
        onClick={handleStartEdit}
        svgStyles={{ height: iconSize, width: iconSize }}
        className={classes.iconButton}
      >
        <DiscoIcon icon={"iconsax.edit-2"} />
      </DiscoIconButton>
    </div>
  )

  function handleStartEdit() {
    setIsInput(true)
    onStartEdit?.()
    initialValue.current = valueStr
  }

  function handleStopEdit() {
    setIsInput(false)
    onStopEdit?.()
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      handleStopEdit()
    } else if (e.key === "Escape") {
      // Revert the input value
      onChange?.(initialValue.current)
      handleStopEdit()
    }
  }
}

type StyleProps = {
  size: "large" | "medium"
}

const useStyles = makeUseStyles((theme) => ({
  static: ({ size }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(size === "large" ? 1.5 : 0.5),
    minHeight: size === "large" ? "40px" : "36px",
    minWidth: 0,
  }),
  error: {
    border: "2px solid",
    borderColor: theme.palette.error.main,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(0.25, 1),
  },
  inputRoot: ({ size }: StyleProps) => ({
    height: size === "large" ? undefined : "36px",
  }),
  input: ({ size }: StyleProps) => ({
    ...theme.typography[size === "large" ? "heading-md" : "body-md"],
    ...theme.typography.modifiers.fontWeight[size === "large" ? 700 : 600],
  }),
  iconButton: {
    padding: theme.spacing(0.5),
  },
}))
