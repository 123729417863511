import ErrorIcon from "@/core/ui/disco/icons/color-icons/cross-fill-light.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import PaidFeatureChip from "@/organization/entitlements/PaidFeatureChip"
import mergeClasses from "@assets/style/util/mergeClasses"
import { DiscoInputSkeleton, DiscoText, DiscoTooltip, DiscoTooltipProps } from "@disco-ui"
import { FormControl, FormControlProps, FormLabelProps, Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { Entitlement } from "@utils/hook/__generated__/useHasEntitlementActiveOrganizationFragment.graphql"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { TestIDProps } from "@utils/typeUtils"
import React, { forwardRef, ReactNode } from "react"
import DiscoFormLabel from "../label/DiscoFormLabel"

export type DiscoFormControlVariant = "one-column" | "two-column"
export interface DiscoFormControlProps
  extends Omit<FormControlProps, "children" | "variant">,
    TestIDProps {
  errorMessages?: string[]
  label?: React.ReactNode
  description?: React.ReactNode
  children: React.ReactNode
  marginTop?: number
  marginBottom?: number
  labelClasses?: FormLabelProps["classes"]
  variant?: DiscoFormControlVariant
  entitlement?: Entitlement
  tooltip?: string | JSX.Element
  titleTooltip?: {
    label: string | JSX.Element
    placement?: DiscoTooltipProps["placement"]
  }
  titleAction?: JSX.Element
  tooltipChildren?: React.ReactElement
  sublabel?: React.ReactNode
  growLabel?: boolean
  disableFullWidth?: boolean
  optional?: boolean
  hideErrors?: boolean
}

const DiscoFormControl = forwardRef<HTMLDivElement, DiscoFormControlProps>(
  (
    {
      errorMessages,
      label,
      children,
      marginTop = 0,
      marginBottom = 2.5,
      classes: propsClasses,
      title,
      disableFullWidth = false,
      description,
      labelClasses,
      variant = "one-column",
      entitlement,
      testid = "DiscoFormControl",
      disabled,
      optional = false,
      tooltip,
      titleTooltip,
      titleAction,
      tooltipChildren,
      sublabel,
      growLabel = false,
      hideErrors = false,
      error = Boolean(errorMessages?.length),
      ...props
    },
    ref
  ) => {
    const classes = useStyles({
      marginTop,
      marginBottom,
      variant,
      growLabel,
      hasTooltip: Boolean(tooltip),
    })

    const hasEntitlement = useHasEntitlement(entitlement)
    const missingEntitlement = Boolean(entitlement && !hasEntitlement)

    if (variant === "one-column") {
      return (
        <FormControl
          ref={ref}
          fullWidth={!disableFullWidth}
          classes={mergeClasses({ root: classes[`${variant}-root`] }, propsClasses)}
          disabled={missingEntitlement || disabled}
          data-testid={testid}
          error={error}
          {...props}
        >
          {title && (
            <span className={classes.titleContainer}>
              <div className={classes.titleGroup}>
                <DiscoText variant={"body-md-600"}>{title}</DiscoText>
                {titleTooltip && (
                  <DiscoTooltip
                    content={titleTooltip.label}
                    placement={titleTooltip.placement}
                    className={classes.titleTooltipIcon}
                  />
                )}
              </div>
              {titleAction}
            </span>
          )}

          {(label || sublabel || entitlement) && (
            <DiscoFormLabel
              classes={mergeClasses(
                { root: classes[`${variant}-label-root`] },
                labelClasses
              )}
            >
              <span className={classes["one-column-label-title"]}>
                {label}
                {entitlement && <PaidFeatureChip entitlement={entitlement} />}
                {(tooltip || description) && (
                  <DiscoTooltip
                    content={tooltip || description!}
                    className={classes.tooltipIcon}
                  >
                    {tooltipChildren ? <span>{tooltipChildren}</span> : undefined}
                  </DiscoTooltip>
                )}
                {optional && <span className={classes.optionalText}>{"(Optional)"}</span>}
              </span>
              {sublabel &&
                (typeof sublabel === "string" ? (
                  <DiscoText variant={"body-sm"} color={"text.secondary"}>
                    {sublabel}
                  </DiscoText>
                ) : (
                  sublabel
                ))}
            </DiscoFormLabel>
          )}
          {children}
          {renderErrors()}
        </FormControl>
      )
    }

    const subtext = sublabel || description

    return (
      <FormControl
        fullWidth
        title={title}
        classes={mergeClasses({ root: classes[`${variant}-root`] }, propsClasses)}
        disabled={missingEntitlement || disabled}
        data-testid={testid}
        {...props}
      >
        <DiscoFormLabel
          classes={mergeClasses({ root: classes[`${variant}-label-root`] }, labelClasses)}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Grid container alignItems={"center"}>
            {(title || label || entitlement) && (
              <>
                {(title || label) && (
                  <div className={classes.twoColumnTitleWrapper}>
                    {title || typeof label === "string" ? (
                      <DiscoText variant={"body-md-600"} display={"inline"}>
                        {title || label}
                      </DiscoText>
                    ) : (
                      label
                    )}
                  </div>
                )}
                {entitlement && <PaidFeatureChip entitlement={entitlement} />}
              </>
            )}
            {tooltip && (
              <DiscoTooltip content={tooltip}>
                {tooltipChildren ? <span>{tooltipChildren}</span> : undefined}
              </DiscoTooltip>
            )}
          </Grid>
          {subtext &&
            (typeof subtext === "string" ? (
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {subtext}
              </DiscoText>
            ) : (
              subtext
            ))}
        </DiscoFormLabel>
        <div className={classes.input}>
          {children}
          {renderErrors()}
        </div>
      </FormControl>
    )

    function renderErrors() {
      if (hideErrors) return null
      if (!errorMessages) return null
      return errorMessages.map((msg) => (
        <div key={msg} className={classes.errorMessage}>
          <ErrorIcon width={24} height={24} />
          <DiscoText
            color={"text.danger"}
            variant={"body-sm"}
            testid={`${testid}.error-message`}
          >
            {msg}
          </DiscoText>
        </div>
      ))
    }
  }
)

interface StyleProps {
  marginTop: number
  marginBottom: number
  variant: DiscoFormControlVariant
  growLabel: boolean
  hasTooltip: boolean
}

const useStyles = makeUseStyles((theme) => ({
  "one-column-root": {
    marginTop: (props: StyleProps) => theme.spacing(props.marginTop),
    marginBottom: (props: StyleProps) => theme.spacing(props.marginBottom),
    padding: theme.spacing(0, 0.25, 0, 0.25),
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  "two-column-root": {
    marginTop: (props: StyleProps) => theme.spacing(props.marginTop),
    marginBottom: (props: StyleProps) => theme.spacing(props.marginBottom),
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(0.5),
      flexWrap: "wrap",
    },
  },
  optionalText: {
    color: theme.palette.grey[400],
  },
  error: {
    color: theme.palette.text.danger,
  },
  "one-column-label-root": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  "one-column-label-title": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    width: "100%",
  },
  "two-column-label-root": {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      flex: (props: StyleProps) => (props.growLabel ? "1 1 auto" : "0 0 50%"),
    },
    [theme.breakpoints.down("xs")]: {
      flex: "0 0 100%",
    },
  },
  input: {
    [theme.breakpoints.up("sm")]: {
      flex: (props: StyleProps) =>
        props.variant === "two-column" && props.growLabel ? "0 1 auto" : "0 1 100%",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "0 0 100%",
    },
  },
  twoColumnTitleWrapper: ({ hasTooltip }: StyleProps) => ({
    marginBottom: hasTooltip ? undefined : theme.spacing(0.5),
    marginRight: theme.spacing(1),
  }),
  errorMessage: {
    "& svg": {
      flexShrink: 0,
    },
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    gap: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(0.75),
    },
  },
  tooltipIcon: {
    // Make the icon take up less vertical space so it fits with "body-sm" labels
    margin: theme.spacing(-0.5, 0),
  },
  titleTooltipIcon: {
    margin: theme.spacing(0, 0.5),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5),
  },
  titleGroup: {
    display: "flex",
    alignItems: "center",
  },
}))

interface SkeletonProps {
  label?: ReactNode
  input?: ReactNode
  variant?: DiscoFormControlVariant
  marginBottom?: number
}

export function DiscoFormControlSkeleton({
  label,
  input,
  variant = "two-column",
  marginBottom = 2.5,
}: SkeletonProps) {
  const classes = useSkeletonStyles({ marginBottom })
  return (
    <Grid
      container
      justifyContent={"space-between"}
      spacing={1}
      className={classes.container}
      direction={variant === "two-column" ? "row" : "column"}
    >
      <Grid
        container
        item
        xs={12}
        sm={variant === "two-column" ? 6 : 12}
        direction={"column"}
      >
        {label || (
          <>
            <Skeleton width={"30%"} height={"28px"} />
            {variant === "two-column" && <Skeleton width={"60%"} height={"22px"} />}
          </>
        )}
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={variant === "two-column" ? 6 : 12}
        direction={"column"}
      >
        {input || <DiscoInputSkeleton />}
      </Grid>
    </Grid>
  )
}

type SkeletonStyleProps = {
  marginBottom: number
}

const useSkeletonStyles = makeUseStyles((theme) => ({
  container: ({ marginBottom }: SkeletonStyleProps) => ({
    marginBottom: theme.spacing(marginBottom),
    flexWrap: "nowrap",
  }),
}))

export default DiscoFormControl
